import React, { useState } from "react";
import { useDiy } from "../../../../context/DiyContext";
import EventPayments from "./Event/EventPayments";
import ArticlePayments from "./Article/ArticlePayments";
import {
  BUSINESS_DIRECTORY_LISTING,
  CANDIDATE_STATEMENTS,
  CLASSIFIED,
  COLUMN,
  EVENT,
  FREELANCE,
  LEGAL_NOTICE,
  LETTERS_TO_THE_EDITOR,
  MILESTONE,
  OBITUARY,
  PRESS_RELEASE,
  REAL_ESTATE_LISTING,
} from "../../diyConstants";
import ClassifiedPayments from "./Classified/ClassifiedPayments";
import DirectoryPayments from "./Directory/DirectoryPayments";
import MilestonePayments from "./Milestone/MilestonePayments";
import ObituaryPayments from "./Obituary/ObituaryPayments";
import RealEstatePayments from "./RealEstate/RealEstatePayments";
import LegalNoticePayments from "./LegalNotice/LegalNoticePayment";
import Checkbox from "../../../../common/Checkbox/Checkbox";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";

const PaymentsByContributionType = ({
  handleProfitSelection,
  setPriceSelectStatus,
  priceSelectStatus,
  handlePoliticalCandidatesSelection,
  setNonProfitCheckboxStatus,
  nonProfitCheckboxStatus,
  canApplyForExemption,
  isContributor,
  townsWithClusters,
  envUrl,
  currentUserId,
  handleExemptionRequest,
  handleNonProfitSelection,
  handleGovernmentSelection,
  colors,
}) => {
  const { contributionType } = useDiy();
  const { adFree, setAdFree } = useDiy();
  const { adFlag } = useDiy();

  const handleCheckboxChange = (event) => {
    setAdFree(event.target.checked);
  };

  const showArticlePayments = [
    PRESS_RELEASE,
    FREELANCE,
    COLUMN,
    CANDIDATE_STATEMENTS,
    LETTERS_TO_THE_EDITOR,
  ].includes(contributionType);

  const showCheckbox = [PRESS_RELEASE, CANDIDATE_STATEMENTS, COLUMN].includes(
    contributionType
  );
  if (showArticlePayments) {
    return (
      <div>
        <ArticlePayments
          handleProfitSelection={handleProfitSelection}
          setPriceSelectStatus={setPriceSelectStatus}
          priceSelectStatus={priceSelectStatus}
          nonProfitCheckboxStatus={nonProfitCheckboxStatus}
          canApplyForExemption={canApplyForExemption}
          isContributor={isContributor}
          townsWithClusters={townsWithClusters}
          envUrl={envUrl}
          currentUserId={currentUserId}
          handleExemptionRequest={handleExemptionRequest}
          handleGovernmentSelection={handleGovernmentSelection}
          colors={colors}
          handleNonProfitSelection={handleNonProfitSelection}
          setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
          handlePoliticalCandidatesSelection={
            handlePoliticalCandidatesSelection
          }
        />
        {showCheckbox && adFlag && (
          <Checkbox
            label={
              <Typography level="t4_text_4">
                {labels.AD_FREE_CONTENT_DIY}
              </Typography>
            }
            onChange={handleCheckboxChange}
            checkboxColor={"#7FC035"}
            checked={adFree}
          />
        )}
      </div>
    );
  }

  switch (contributionType) {
    case EVENT:
      return (
        <div>
          <EventPayments
            handleProfitSelection={handleProfitSelection}
            setPriceSelectStatus={setPriceSelectStatus}
            priceSelectStatus={priceSelectStatus}
            handlePoliticalCandidatesSelection={
              handlePoliticalCandidatesSelection
            }
            setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
            canApplyForExemption={canApplyForExemption}
            isContributor={isContributor}
            townsWithClusters={townsWithClusters}
            envUrl={envUrl}
            currentUserId={currentUserId}
            handleExemptionRequest={handleExemptionRequest}
            nonProfitCheckboxStatus={nonProfitCheckboxStatus}
            handleGovernmentSelection={handleGovernmentSelection}
            colors={colors}
            handleNonProfitSelection={handleNonProfitSelection}
          />
          {adFlag && (
            <Checkbox
              label={
                <Typography level="t4_text_4">
                  {labels.AD_FREE_CONTENT_DIY}
                </Typography>
              }
              onChange={handleCheckboxChange}
              checkboxColor={"#7FC035"}
              checked={adFree}
            />
          )}
        </div>
      );
    case CLASSIFIED:
      return (
        <div>
          <ClassifiedPayments
            handleProfitSelection={handleProfitSelection}
            colors={colors}
          />
          {adFlag && (
            <Checkbox
              label={
                <Typography level="t4_text_4">
                  {labels.AD_FREE_CONTENT_DIY}
                </Typography>
              }
              onChange={handleCheckboxChange}
              checkboxColor={"#7FC035"}
              checked={adFree}
            />
          )}
        </div>
      );
    case BUSINESS_DIRECTORY_LISTING:
      return (
        <DirectoryPayments
          priceSelectStatus={priceSelectStatus}
          handleProfitSelection={handleProfitSelection}
          setPriceSelectStatus={setPriceSelectStatus}
          canApplyForExemption={canApplyForExemption}
          isContributor={isContributor}
          townsWithClusters={townsWithClusters}
          envUrl={envUrl}
          currentUserId={currentUserId}
          handleExemptionRequest={handleExemptionRequest}
          nonProfitCheckboxStatus={nonProfitCheckboxStatus}
          setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
          handleNonProfitSelection={handleNonProfitSelection}
          colors={colors}
        />
      );
    case MILESTONE:
      return <MilestonePayments colors={colors} />;
    case OBITUARY:
      return <ObituaryPayments colors={colors} />;
    case REAL_ESTATE_LISTING:
      return <RealEstatePayments colors={colors} />;
    case LEGAL_NOTICE:
      return <LegalNoticePayments colors={colors} />;
    default:
      return <></>;
  }
};

export default PaymentsByContributionType;
