import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import Typography from "../../../../common/Typography/Typography";
import {
  CANDIDATE_STATEMENTS,
  GRAY,
  GREEN,
  LETTERS_TO_THE_EDITOR,
  MILESTONES,
  OBITUARY,
  WHITE,
} from "../../diyConstants";
import ShoppingListRow from "../ShoppingListRow/ShoppingListRow";
import {
  handleTownRemoval,
  updateItemCountListener,
} from "../../../../utils/listeners";
import { useStyles } from "./ShoppingList.style";
import FacebookList from "../FacebookList/FacebookList";

const ShoppingList = ({
  townsSelected,
  formType,
  showFacebookBlock,
  itemCountForEventDraft,
  town,
}) => {
  const classes = useStyles();
  const [itemCount, setItemCount] = useState(
    itemCountForEventDraft ? itemCountForEventDraft : undefined
  );
  const [facebookTownsSelected, setFacebookTownsSelected] = useState([]);

  useEffect(() => {
    if (townsSelected?.length > 0) {
      setFacebookTownsSelected(
        townsSelected.filter((town) => town.fb_selected)
      );
    } else {
      setFacebookTownsSelected([]);
    }
  }, [townsSelected]);

  updateItemCountListener(setItemCount);

  const getTotalPurchaseAmmount = () => {
    const initialValue = 0;
    let total = 0;
    if (townsSelected && formType !== LETTERS_TO_THE_EDITOR) {
      total = townsSelected.reduce(
        (accumulator, current) => accumulator + current.price,
        initialValue
      );
      if (showFacebookBlock) {
        total += facebookTownsSelected.reduce(
          (accumulator, current) => accumulator + current.fb_price,
          initialValue
        );
      }
    }
    return itemCount ? itemCount * total : total;
  };

  const shouldDisplayFacebookSummary = () => {
    if (
      [
        LETTERS_TO_THE_EDITOR,
        MILESTONES,
        OBITUARY,
      ].includes(formType) ||
      !showFacebookBlock
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div
        className={
          townsSelected.length > 0 ? classes.with_items : classes.empty
        }
      >
        {townsSelected.length > 0 ? (
          townsSelected.map((town) => (
            <ShoppingListRow
              key={`town-shopping-cart-item-${town.id}`}
              town={town}
              onRemove={handleTownRemoval}
              formType={formType}
              isFacebook={false}
              itemQuantity={itemCount}
            />
          ))
        ) : (
          <Typography level="t1_text_1" color={GREEN} bold>
            {town?.under_license_contract
              ? labels.NO_PUBLICATIONS_SELECTED
              : labels.NO_TOWNS_SELECTED}
          </Typography>
        )}
      </div>

      {shouldDisplayFacebookSummary() && (
        <FacebookList
          facebookTownsSelected={townsSelected}
          itemCount={itemCount}
        />
      )}

      <div className={classes.purchase_total_container}>
        <div className={classes.purchase_total_label}>
          <Typography level="t1_text_1" color={GRAY} bold>
            {labels.PURCHASE_TOTAL}
          </Typography>
        </div>
        <div className={classes.purchase_total_ammount}>
          <Typography level="t3_text_3" color={GREEN} bold>
            {labels.DOLLAR_SIGN + getTotalPurchaseAmmount()}
          </Typography>
        </div>
      </div>
    </>
  );
};

ShoppingList.propTypes = {
  townsSelected: PropTypes.array,
  formType: PropTypes.string,
  showFacebookBlock: PropTypes.bool,
  itemCountForEventDraft: PropTypes.number,
};

export default ShoppingList;
